import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _414c621c = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _7797eb32 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _b52d975e = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _7ca0c05c = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _015d691f = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _119943e6 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f4963892 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _475a7fc0 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _24a41c14 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _97a93280 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _0575be08 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _72303188 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _176ef870 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _2723daff = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _13265c40 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _55b03025 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _2d8bbaf6 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _414c621c,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _7797eb32,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _b52d975e,
    name: "callback"
  }, {
    path: "/downloads",
    component: _7ca0c05c,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _015d691f,
    name: "guests-details"
  }, {
    path: "/login",
    component: _119943e6,
    name: "login"
  }, {
    path: "/maintenance",
    component: _f4963892,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _475a7fc0,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _24a41c14,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _97a93280,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _0575be08,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _72303188,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _176ef870,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _2723daff,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _13265c40,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _55b03025,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _2d8bbaf6,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
